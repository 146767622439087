
@font-face {
  font-family: 'SharpGroteskLight';
  src: local('Sharp Grotesk Light'), local('SharpGroteskLight'),
    url(https://assets.quadpay.com/assets/fonts/sharpGrotesk/SharpGroteskLight20.woff2)
      format('woff2'),
    url(https://assets.quadpay.com/assets/fonts/sharpGrotesk/SharpGroteskLight20.woff)
      format('woff'),
      url(https://assets.quadpay.com/assets/fonts/sharpGrotesk/SharpGroteskLight25.woff2)
        format('woff2'),
      url(https://assets.quadpay.com/assets/fonts/sharpGrotesk/SharpGroteskLight25.woff)
        format('woff'),
    url(https://assets.quadpay.com/assets/fonts/sharpGrotesk/SharpGroteskLight.otf)
      format('opentype'),
    url(https://assets.quadpay.com/assets/fonts/sharpGrotesk/SharpGroteskLight.ttf)
      format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'SharpGroteskBook20';
  src: local('Sharp Grotesk Book'), local('SharpGroteskBook'),
    url(https://assets.quadpay.com/assets/fonts/sharpGrotesk/SharpGroteskBook20.woff2)
      format('woff2'),
    url(https://assets.quadpay.com/assets/fonts/sharpGrotesk/SharpGroteskBook20.woff)
      format('woff'),
    url(https://assets.quadpay.com/assets/fonts/sharpGrotesk/SharpGroteskBook.otf)
      format('opentype'),
    url(https://assets.quadpay.com/assets/fonts/sharpGrotesk/SharpGroteskBook.ttf)
      format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'SharpGroteskSemiBold25';
  src: local('Sharp Grotesk SemiBold'), local('SharpGroteskSemiBold'),
    url(https://assets.quadpay.com/assets/fonts/sharpGrotesk/SharpGroteskSmBold25.woff2)
      format('woff2'),
    url(https://assets.quadpay.com/assets/fonts/sharpGrotesk/SharpGroteskSmBold25.woff)
      format('woff'),
    url(https://assets.quadpay.com/assets/fonts/sharpGrotesk/SharpGroteskSemiBold25.otf)
      format('opentype'),
    url(https://assets.quadpay.com/assets/fonts/sharpGrotesk/SharpGroteskSemiBold25.ttf)
      format('truetype');
  font-weight: 600 700;
  font-style: normal;
}
@font-face {
  font-family: 'Cooper';
  src: local('Cooper'), local('Cooper'),
    url(https://assets.quadpay.com/assets/fonts/cooper/cooper-light.woff2)
      format('woff2'),
    url(https://assets.quadpay.com/assets/fonts/cooper/cooper-light.woff)
      format('woff'),
    url(https://assets.quadpay.com/assets/fonts/cooper/cooper-light.otf)
      format('opentype'),
    url(https://assets.quadpay.com/assets/fonts/cooper/cooper-light.ttf)
      format('truetype');
  font-display: swap;
}

